// Import Modules
import React from 'react'
import { db } from '../../../firebase'
// import axios from "axios"

// Import Hooks
import useUser from 'hooks/useUser'

const FormatOption = ({ formatName, activeClass, format }) => {
  const { savetaxInfo } = useUser()

  const updateConfFirebase = async (formato) => {
    let docRef = db.collection('configuraciones').doc(savetaxInfo.usuarioPK.codCia.toString())
    await docRef.update({ formato })
  }

  // const updateConfAPI = async(formato)=>{
  //     let response = await axios.post(`https://savetax.facturacionsavetaxcr.com/savetax/api/v1/${savetaxInfo.usuarioPK.codCia}/modificar-tipo-reporte`, {
  //         "codCia": savetaxInfo.usuarioPK.codCia,
  //         "formato": formato.toString()
  //     })
  //     return response.data
  // }

  const saveFormatConf = async (formato) => {
    if (!savetaxInfo?.cia?.ruc) return
    await updateConfFirebase(formato)
  }
  return (
    <div className={`formatOption ${activeClass || ''}`} onClick={() => saveFormatConf(format)}>
      <div className="checkSymbol">
        <i className="bi bi-check"></i>
      </div>
      <div className="contentMain">
        <i className="bi bi-file-richtext"></i>
        <span>{formatName}</span>
      </div>
      <button>
        <b>Previsualizar</b>
        <i className="bi bi-eye-fill"></i>
      </button>
    </div>
  )
}

export default FormatOption
