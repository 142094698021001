import { getDatosGeograficos } from 'actions/misc'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import { motion } from 'framer-motion'
import useMisc from 'hooks/useMisc'
import React, { useEffect,useState } from 'react'
import { useDispatch } from 'react-redux'
import { useGetPersona } from './formHelper'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import CircularProgress from 'components/CircularProgress/CircularProgress'

const InfoCliente = ({ form, stepUp, stepDown, handleChange, variants }) => {
  const dispatch = useDispatch()
  const { persona } = form
  const { paises, provincias, cantones, distritos, barrios, isLoadingDatosGeograficos } = useMisc()
  const [validando, setValidando] = useState(false)

  const getPersona = useGetPersona()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoadingDatosGeograficos) {
      dispatch(getDatosGeograficos)
    }
  }, [isLoadingDatosGeograficos, dispatch])

  const saveUser = async () => {
    setValidando(true)
    const res = await getPersona(form)
    //console.log("res",res)
    if (res === 'La cuenta ya exite') {
      toast.error('La cuenta ya exite')
    }else{
      toast.success('Cuenta creada exitosamente! se han enviado las credenciales a tu correo')
      navigate('/ingresar')
    }
    setValidando(false)
  }

  return (
    <motion.div initial={variants.initial} animate={variants.animate} exit={variants.exit} transition={variants.transition}>
      <div className="Form registroForm">
        <div className="carrusel step01">
          <div className="slide infoStep">
            <div className="steps">
              <span>
                Paso <b>2</b> de 4
              </span>
            </div>

            <h4>
              <button className="backButton" onClick={stepDown}>
                <Down />
              </button>
              Registro de Datos
            </h4>

            <div className="FormInfo">
              <label htmlFor="nombreDescripcion" className="text" style={{ width: '50%' }}>
                Nombre
                <input
                  type="text"
                  autoComplete="off"
                  disabled={true}
                  value={persona.nombreCia}
                  placeholder="Nombre"
                  id="nombreDescripcion"
                />
              </label>

              <label htmlFor="NúmeroIdentidad" className="text" style={{ width: '25%' }}>
                Número Identidad
                <div className="searchForm">
                  <input
                    type="text"
                    autoComplete="off"
                    disabled={true}
                    value={persona.ruc}
                    placeholder="Número Identidad"
                    id="NúmeroIdentidad"
                  />
                </div>
              </label>

              <label htmlFor="Tipodocumento" className="text" style={{ width: '25%' }}>
                Tipo documento
                <select id="Tipodocumento" disabled={true} value={persona.tipoPersona}>
                  <option value="01">PERSONA FISICA</option>
                  <option value="02">PERSONA JURIDICA</option>
                </select>
              </label>

              <label htmlFor="País" className="text" style={{ width: '20%' }}>
                País
                <input
                  autoComplete="off"
                  type="text"
                  disabled={true}
                  value={paises?.[0]?.nombrePais}
                  placeholder="Pais"
                  id="País"
                />
              </label>

              <label htmlFor="Provincia" className="text" style={{ width: '30%' }}>
                Provincia
                <select
                  id="Provincia"
                  name="persona.provincia"
                  onChange={handleChange}
                  value={persona.provincia ?? ''}
                  disabled={!provincias.length}
                >
                  <option value="">Seleccione</option>
                  {provincias?.map((departamento) => (
                    <option key={departamento.idDepartamento} value={departamento.idDepartamento}>
                      {departamento.nombreDepartamento}
                    </option>
                  ))}
                </select>
              </label>

              <label htmlFor="Cantón" className="text" style={{ width: '25%' }}>
                Cantón
                <select
                  id="Cantón"
                  onChange={handleChange}
                  name="persona.canton"
                  value={persona.canton || ''}
                  disabled={!cantones.length}
                >
                  <option value="">Seleccione</option>
                  {cantones
                    ?.filter((c) => +c?.idDepartamento?.idDepartamento === +(persona.provincia ?? 1))
                    ?.map((canton) => (
                      <option key={canton.idMunicipio} value={canton.idMunicipio}>
                        {canton?.nombreMunicipio}
                      </option>
                    ))}
                </select>
              </label>

              <label htmlFor="Distrito" className="text" style={{ width: '25%' }}>
                Distrito
                <select
                  id="Distrito"
                  onChange={handleChange}
                  name="persona.distrito"
                  value={persona.distrito || ''}
                  disabled={!distritos.length}
                >
                  <option value="">Seleccione</option>
                  {distritos
                    ?.filter((d) => +d.idMunicipio.idMunicipio === +(persona.canton ?? 101))
                    ?.map((distrito) => (
                      <option key={distrito.idDistrito} value={distrito.idDistrito}>
                        {distrito.nombre}
                      </option>
                    ))}
                </select>
              </label>

              <label htmlFor="Barrio" className="text" style={{ width: '25%' }}>
                Barrio
                <select
                  id="Barrio"
                  onChange={handleChange}
                  name="persona.barrio"
                  value={persona.barrio || ''}
                  disabled={!barrios.length}
                >
                  <option value="">Seleccione</option>
                  {barrios
                    ?.filter((b) => +b.idDistrito.idDistrito === +(persona.distrito ?? 10101))
                    ?.map((barrio) => (
                      <option key={barrio.idBarrio} value={barrio.idBarrio}>
                        {barrio.nombre}
                      </option>
                    ))}
                </select>
              </label>

              <label htmlFor="Teléfono" className="text" style={{ width: '25%' }}>
                Teléfono
                <input
                  onChange={handleChange}
                  name="persona.telefonos"
                  value={persona.telefonos || ''}
                  autoComplete="off"
                  type="text"
                  placeholder="Teléfono"
                  id="Teléfono"
                />
              </label>

              <label htmlFor="Teléfono" className="text" style={{ width: '25%' }}>
                Teléfono Movil
                <input
                  onChange={handleChange}
                  name="persona.celular"
                  value={persona.celular || ''}
                  autoComplete="off"
                  type="text"
                  placeholder="Teléfono"
                  id="Teléfono"
                />
              </label>

              <label htmlFor="Correo" className="text" style={{ width: '25%' }}>
                Correo
                <input
                  onChange={handleChange}
                  name="persona.correoElectronico"
                  value={persona.correoElectronico || ''}
                  autoComplete="off"
                  type="text"
                  placeholder="Correo"
                  id="Correo"
                />
              </label>

              <label htmlFor="Dirección" className="text" style={{ width: '100%' }}>
                Dirección
                <input
                  onChange={handleChange}
                  name="persona.direccion"
                  value={persona.direccion || ''}
                  autoComplete="off"
                  type="text"
                  placeholder="Dirección"
                  id="Dirección"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="actionFixed">
        <button
          className="btnRegistro"
          onClick={() => {
            saveUser()
          }}
          disabled={!persona.provincia || !persona.canton || !persona.distrito || !persona.barrio}
        >
          Guardar Datos
        </button>
      </div>
    </motion.div>
  )
}

export default InfoCliente
