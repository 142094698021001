import Text from 'components/FormField/Text'
import { fileToBase64 } from 'helpers/files'
import React from 'react'

const ComprobantesElectronicos = ({ company, setCompany }) => {
  const { resolucionIva, resolucionIca, contraseniaFirmaElectronica } = company ?? {}

  return (
    <div className="formComprobantes">
      <Text
        label="Usuario de Ingreso (Generado por ATV)"
        onChange={(e) => {
          setCompany((prev) => ({
            ...prev,
            resolucionIva: e.target.value,
          }))
        }}
        value={resolucionIva ?? ''}
      />
      <Text
        label="Contraseña de Ingreso (Generado por ATV)"
        onChange={(e) => {
          setCompany((prev) => ({
            ...prev,
            resolucionIca: e.target.value,
          }))
        }}
        value={resolucionIca ?? ''}
      />
      <Text
        label="Contraseña firma electrónica"
        onChange={(e) => {
          setCompany((prev) => ({
            ...prev,
            contraseniaFirmaElectronica: e.target.value,
          }))
        }}
        value={contraseniaFirmaElectronica ?? ''}
      />

      <label htmlFor="cargarFirma" className="text span2">
        Cargar Firma
        <input
          type="file"
          id="cargarFirma"
          onChange={async (e) => {
            const base64File = await fileToBase64(e.target.files[0])
            setCompany((prev) => ({
              ...prev,
              ubicacionFirmaElectronica: base64File,
            }))
          }}
        />
      </label>
    </div>
  )
}

export default ComprobantesElectronicos
