/* eslint-disable no-unused-vars */
// Import Modules
import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

// Import StyleSheet
import './Escritorio.css'

// Import context
import Global from '../../contexts/Global/Global'

// Import Components
import CircularProgress from '../CircularProgress/CircularProgress'
import DocumentosRecibidosResumen from './DocumentosRecibidosResumen'
import ChartsDashboard from './ChartsDashboard/ChartsDashboard'

// Import Hooks
import { useDispatch } from 'react-redux'
import useUser from '../../hooks/useUser'
import useClientes from '../../hooks/useClientes'
import useFacturas from '../../hooks/useFacturas'
import useDocumentos from '../../hooks/useDocumentos'
import useProductos from '../../hooks/useProductos'
import useAdmin from '../../hooks/useAdmin'
import { getClients } from '../../actions/personas'
import { getSucursales } from '../../actions/administracion'
import { getProductos } from '../../actions/productos'
import { getDocumentosRecibidos } from '../../actions/documentos'
import { getFacturasElectronicas } from '../../actions/facturas'
import { useNavigate } from 'react-router-dom'
import { getInstance } from 'api/apiInstance'

const InfoWidget = ({ items, title, link, handleClick }) => (
  <div className="info">
    {items?.length ? (
      <>
        <b>{items?.length}</b> <span> {title}</span>
        <Link to={link} className="moreDetails" onClick={handleClick}>
          Ver más detalle
        </Link>
      </>
    ) : (
      <CircularProgress />
    )}
  </div>
)

const Escritorio = () => {
  const dispatch = useDispatch()
  const { savetaxInfo, user } = useUser()
  const { clientes, isLoadingClientes } = useClientes()
  const { facturasElectronicas, isLoadingFacturasElectronicas } = useFacturas()
  const { documentosRecibidos, isLoadingDocumentosRecibidos } = useDocumentos()
  const { productos, isLoadingProductos } = useProductos()
  const { sucursales, isLoadingSucursales } = useAdmin()
  const navigate = useNavigate()

  const [documentosPendientes, setDocumentosPendientes] = useState([])

  const globalContext = useContext(Global)

  const setTitleSection = (title) => {
    let sectionTitle = document.querySelector('.titleSection')
    sectionTitle.innerHTML = title
  }

  const openReaderPDF = async (url, descripcion, index, doc) => {
    globalContext.updateGlobalContext('currentDoc', url)

    const request = await getInstance("fe")
    const base64 = await request.get(`vta-movm/pdf-base64/${savetaxInfo.cia.codCia}/${doc.tipoDoctoVta.idTipoDoctoVta}/1/${doc.docRecibidosPK.id}`)

    globalContext.updateGlobalContext('base64PDF', base64.data)
    globalContext.updateGlobalContext('indexPDF', index)
    let readerPDF = document.querySelector('.readerPDF')
    let titleReader = document.querySelector('.titleReader')

    let downloadReader = document.querySelector('.downloadReader')
    console.log('downloadReader')
    console.log(readerPDF)
    downloadReader.setAttribute('download', descripcion)
    readerPDF.className = 'readerPDF'
    titleReader.innerHTML = descripcion
  }

  const cia = savetaxInfo?.cia?.codCia

  useEffect(() => {
    if (isLoadingSucursales && cia) dispatch(getSucursales(cia))
  }, [dispatch, cia, isLoadingSucursales])

  useEffect(() => {
    if (isLoadingClientes && cia) dispatch(getClients(cia))
  }, [dispatch, cia, isLoadingClientes])

  useEffect(() => {
    if (isLoadingFacturasElectronicas && cia) dispatch(getFacturasElectronicas(cia))
  }, [dispatch, cia, isLoadingFacturasElectronicas])

  useEffect(() => {
    if (isLoadingDocumentosRecibidos && cia) {
      dispatch(getDocumentosRecibidos(cia))
    }

    setDocumentosPendientes(documentosRecibidos?.filter((doc) => !doc.descripcionSri))
  }, [dispatch, cia, documentosRecibidos, isLoadingDocumentosRecibidos])

  useEffect(() => {
    if (isLoadingProductos && cia) dispatch(getProductos(cia))
  }, [dispatch, cia, isLoadingProductos])

  return (
    <div className="Escritorio">
      <div className="mainEscritorio">
        <div className="statusContentMain elementEscritorio">
          <div className="statusContent">
            <h4>Clientes</h4>
            <div className="content">
              <i className="bi bi-people"></i>
              <div className="info">
                <InfoWidget
                  items={clientes}
                  title="Clientes registrados"
                  link="/personas"
                  handleClick={() => setTitleSection('Clientes')}
                />
              </div>
            </div>
          </div>
          <div className="statusContent">
            <h4>Productos</h4>
            <div className="content">
              <i className="bi bi-box-seam"></i>
              <InfoWidget
                items={productos}
                title="Productos registrados"
                link="/productos"
                handleClick={() => setTitleSection('Productos')}
              />
            </div>
          </div>
          <hr />
          <div className="actionsButtons">
            <div className="carrusel">
              <button
                onClick={() => {
                  navigate('/facturasElectronicas')
                  setTimeout(() => {
                    document.querySelector('.addContent')?.click()
                  }, 1000)
                }}
              >
                <i className="bi bi-file-earmark-plus"></i>
                Agregar Factura Electronica
              </button>
              <button onClick={() => navigate('/documentosEmitidos')}>
                <i className="bi bi-file-earmark-arrow-up"></i>
                Ver Documentos Emitidos
              </button>
              <button onClick={() => navigate('/documentosRecibidos')}>
                <i className="bi bi-file-arrow-down"></i>
                Ver Documentos Recibidos
              </button>
              <button onClick={() => navigate('/productos')}>
                <i className="bi bi-box-seam"></i>
                Ver Productos
              </button>
              <button onClick={() => navigate('/personas')}>
                <i className="bi bi-people"></i>
                Ver Personas
              </button>
            </div>
          </div>
        </div>
        <ChartsDashboard />
      </div>

      <div className="sidebarEscritorio">
        <DocumentosRecibidosResumen />
      </div>
    </div>
  )
}

export default Escritorio
