import { getTarifas, getTiposDeIva, getUnidadesDeMedida } from 'actions/misc'
import useMisc from 'hooks/useMisc'
import useUser from 'hooks/useUser'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const Productos = ({ product, handleChange, setProduct }) => {
  const dispatch = useDispatch()
  const { isEdit } = product ?? {}
  const { savetaxInfo } = useUser()
  const { unidadesDeMedida, tiposDeIva, tarifas, isLoadingTarifas } = useMisc()

  useEffect(() => {
    if (!unidadesDeMedida.length) dispatch(getUnidadesDeMedida(savetaxInfo.cia.codCia))
  }, [unidadesDeMedida, savetaxInfo, dispatch])

  useEffect(() => {
    if (!tiposDeIva.length) dispatch(getTiposDeIva)
  }, [tiposDeIva, savetaxInfo, dispatch])

  useEffect(() => {
    if (!tarifas) dispatch(getTarifas)
  }, [tarifas, savetaxInfo, dispatch])

  return (
    <>
      <div className="detallesDePedidos sectionForm">
        <div className="titleSectionForm" style={{ width: '100%' }}>
          <h3>Detalles del Producto</h3>
        </div>

        <label htmlFor="nombreDescripcion" className="text" style={{ width: '60%' }}>
          Nombre/Descripción
          <input
            type="text"
            value={product?.nombreProducto}
            onChange={handleChange}
            name={'nombreProducto'}
            placeholder="Nombre/Descripción"
            id="nombreDescripcion"
          />
        </label>

        <label htmlFor="unidadMedida" className="text" style={{ width: '40%' }}>
          Unidad Medida
          <select
            id="unidadMedida"
            onChange={(e) => {
              setProduct((prev) => ({ ...prev, unidadMedida: JSON.parse(e.target[e.target.selectedIndex].dataset.unidad) }))
            }}
            value={product?.unidadMedida?.unidadMedidaPK?.idUnidad ?? ''}
          >
            <option>Seleccione</option>
            {unidadesDeMedida.map((unidad) => (
              <option
                key={unidad.unidadMedidaPK.idUnidad}
                data-unidad={JSON.stringify(unidad)}
                value={unidad.unidadMedidaPK.idUnidad}
              >
                {unidad.nombreUnidad} {unidad.representacion}
              </option>
            ))}
          </select>
        </label>

        <label htmlFor="tipoIVA" className="text" style={{ width: '40%' }}>
          Tipo IVA
          <select
            id="tipoIVA"
            onChange={(e) => {
              setProduct((prev) => ({
                ...prev,
                impuesto: {
                  codigo: '',
                  tipoImpuesto: {
                    ...JSON.parse(e.target[e.target.selectedIndex].dataset.tipo),
                  },
                },
              }))
            }}
            value={product?.impuesto?.tipoImpuesto?.codigoSri ?? ''}
          >
            <option data-tipo={JSON.stringify({}, null, 2)}>Seleccione</option>
            {tiposDeIva.map((tipo) => (
              <option key={tipo.codigoSri} data-tipo={JSON.stringify(tipo, null, 2)} value={tipo.codigoSri}>
                {tipo.descripcion}
              </option>
            ))}
          </select>
        </label>

        <label htmlFor="CodigodeTarifa" className="text" style={{ width: '40%' }}>
          Código de Tarifa
          <select
            disabled={isLoadingTarifas}
            onChange={(e) => {
              setProduct((prev) => ({
                ...prev,
                impuesto: {
                  ...prev.impuesto,
                  ...JSON.parse(e.target[e.target.selectedIndex].dataset.tarifa),
                },
              }))
            }}
            name="impuesto.codigo"
            value={product?.impuesto?.codigo ?? ''}
            id="CodigodeTarifa"
          >
            <option value="">Seleccione</option>

            {product?.impuesto?.tipoImpuesto?.idTipoImpuesto &&
              !isLoadingTarifas &&
              tarifas[product?.impuesto?.tipoImpuesto.idTipoImpuesto].map((tarifa) => (
                <option key={tarifa.codigo} data-tarifa={JSON.stringify(tarifa)} value={tarifa.codigo}>
                  {tarifa.descripcion}
                </option>
              ))}
          </select>
        </label>

        

        <label htmlFor="PrecioUnitario" className="text" style={{ width: '25%' }}>
          Precio Unitario
          <input
            type="text"
            value={product?.precioVenta}
            name="precioVenta"
            placeholder="Precio Unitario"
            onChange={handleChange}
            id="PrecioUnitario"
          />
        </label>

        <label htmlFor="CodigoInterno" className="text" style={{ width: '35%' }}>
          Código Interno
          <input
            disabled={true}
            type="text"
            value={product?.productoPK?.idProducto ?? ''}
            placeholder="Código Interno"
            id="CodigoInterno"
          />
        </label>

        <label htmlFor="PartidaArancelaria" className="text" style={{ width: '40%' }}>
          Partida Arancelaria
          <input
            type="text"
            value={product?.partidaArancelaria}
            name="partidaArancelaria"
            onChange={handleChange}
            placeholder="Partida Arancelaria"
            id="PartidaArancelaria"
          />
        </label>

        <label htmlFor="Estado" className="text" style={{ width: '20%' }}>
          Estado
          <select value={product?.estado ?? ''} id="Estado" onChange={handleChange} name="estado">
            <option value="">Seleccione</option>
            <option value="A">Activo</option>
            <option value="I">Inactivo</option>
          </select>
        </label>

        

        

        
      </div>
    </>
  )
}

export default Productos
