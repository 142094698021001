import React, { useState, useCallback } from 'react'
import Form from 'components/Form/Form'
import _ from 'lodash'
import Encabezado from './EncabezadoYReceptor/Encabezado'
import Receptor from './EncabezadoYReceptor/Receptor'
import Exoneraciones from './Exoneraciones/Exoneraciones'
import FormNavigation from './FormNavigation'
import useUser from 'hooks/useUser'
import SearchClient from './SearchClient/SearchClient'
import LineaDetalles from './LineaDetalles/LineaDetalles'
import BuscarProductos from './BuscarProductos/BuscarProductos'
import IVA from './LineaDetalles/IVA'
import Impuestos from './LineaDetalles/Impuestos'
import Descuentos from './LineaDetalles/Descuentos'
import Sidebar from './Sidebar/Sidebar'
import OtrosCargos from './OtrosCargos/OtrosCargos'
import Contingencias from './Contingencias/Contingencias'
import logo from 'assets/logo_black.png'

const initialFactura = (cia, tipoFactura) => ({
  isEdit: false,
  productEnLinea: {},
  impuestoEnLinea: {
    factor: 0.0,
    monto: 0.0,
    montoPorcentaje: '',
    otroImpuestoVtaDetmPK: {},
    tipoImpuesto: {},
  },
  descuentoEnLinea: {
    descripcion: '',
    descuentoVtaDemPK: {},
    monto: 0.0,
    porcentaje: 0.0,
    valorPorcentaje: 'M',
  },
  otroCargoEnLinea: {
    detalle: '',
    identificacion: '',
    monto: 0.0,
    nombre: '',
    otroCargoVtaMovmPK: {},
    porcentaje: 0.0,
    tipoDoctoOtrosCargos: {},
  },
  cliente: {},
  tipoDoctoVta: {},
  caja: '',
  actividadEconomicaCia: '',
  condicionVenta: '',
  idMoneda: '',
  sucursal: '',
  comentario: '',
  consecutivoContingencia: '',
  descuento: 150,
  estadoFactura: '',
  motivoContingencia: '',
  nota: '',
  status: '',
  tiempo: '',
  tipoVenta: '',
  fechaContingencia: '',
  iva: 0,
  tasaCambio: 1,
  fechaCreacion:
    new Date(Date.now()).getDate() + '/' + (new Date(Date.now()).getMonth() + 1) + '/' + new Date(Date.now()).getFullYear(),
  vtaDetmList: [],
  otroCargoVtaMovmList: [],
  cajas: [],
  tarifas: [],
  formaPagoList: [],
  otroImpuestoVtaDetmList: [],
  descuentoVtaDemList: [],
  actionEnLinea: 'add',
  actionEnLineaOtrosCargos: 'add',
  actionEnLineaDescuentos: 'add',
  openTasaCambio: false,
  total: 0,
  totalExento: 0,
  totalExonerado: 0,
  totalGravados: 0,
  totalIvaDevuelto: 0,
  totalMercExento: 0,
  totalMercExonerado: 0,
  totalMercGravados: 0,
  totalOtrosCargos: 0,
  totalOtrosImpuestos: 0,
  totalSrvExento: 0,
  totalSrvExonerado: 0,
  totalSrvGravados: 0,
  subtotal: 0,
  subtotalExento: 0,
  vtaMovmPK: {
    codCia: cia,
    idSucursal: '',
    idTipoDoctoVta: tipoFactura,
    numDocto: 0,
  },
})



const FormFacturasElectronicas = ({
  editFactura = undefined,
  handleClose,
  tipoFactura,
  facturaActionType,
  title,
  setModal,
  facturas,
  setFacturas,
}) => {
  const { savetaxInfo } = useUser()

  const [factura, setFactura] = useState(() =>
    editFactura?.isEdit ? editFactura : initialFactura(savetaxInfo.cia.codCia, tipoFactura)
  )

  const [section, setSection] = useState('facturaElectronica/Encabezado')

  const handleChange = useCallback((e) => {
    if (e.target.name === "nombreDescripcion") {
      setFactura((prev) => {
        const newFactura = _.cloneDeep(prev)
        if ('nombreProducto' in newFactura.productEnLinea) {
          _.set(newFactura, 'productEnLinea.nombreProducto', e.target.value)
          _.set(newFactura, 'productEnLinea.descripcion', e.target.value)
        }
        return newFactura
      })
    } else {
      setFactura((prev) => {
        const newFactura = _.cloneDeep(prev)
        _.set(newFactura, e.target.name, e.target.value)
        return newFactura
      })
    }
  }, [])

  const handleAddMedioDePage = (e) => {
    setFactura((prev) => ({
      ..._.set(prev, 'formaPagoList', [...e]),
    }))
  }

  const onClose = useCallback(() => {
    handleClose()
    setFactura({ ...savetaxInfo.cia.codCia })
  }, [savetaxInfo, handleClose])

  const displaySection = (section) => {
    switch (section) {
      case 'facturaElectronica/Encabezado':
        return (
          <Encabezado
            setFactura={setFactura}
            factura={factura}
            handleChange={handleChange}
            handleAddMedioDePage={handleAddMedioDePage}
            tipoDocto={tipoFactura}
          />
        )
      case 'facturaElectronica/Receptor':
        return (
          <Receptor
            factura={factura}
            setFactura={setFactura}
            handleChange={handleChange}
            setSection={setSection}
            action={facturaActionType}
          />
        )
      case 'facturaElectronica/SearchClient':
        return <SearchClient factura={factura} setSection={setSection} setFactura={setFactura} action={facturaActionType} />
      case 'facturaElectronica/DetallesFactura':
        return <LineaDetalles factura={factura} setSection={setSection} setFactura={setFactura} handleChange={handleChange} />
      case 'facturaElectronica/BuscarProductos':
        return <BuscarProductos factura={factura} setSection={setSection} setFactura={setFactura} />
      case 'facturaElectronica/IVA':
        return <IVA factura={factura} setSection={setSection} setFactura={setFactura} handleChange={handleChange} />
      case 'facturaElectronica/Impuestos':
        return <Impuestos factura={factura} setSection={setSection} setFactura={setFactura} handleChange={handleChange} />
      case 'facturaElectronica/Descuentos':
        return <Descuentos factura={factura} setSection={setSection} setFactura={setFactura} handleChange={handleChange} />
      case 'facturaElectronica/OtrosCargos':
        return <OtrosCargos factura={factura} setSection={setSection} setFactura={setFactura} handleChange={handleChange} />
      case 'facturaElectronica/Contingencia':
        return <Contingencias factura={factura} handleChange={handleChange} />
      case 'facturaElectronica/Exoneraciones':
        return <Exoneraciones factura={factura} setFactura={setFactura} />
      default:
        return
    }
  }

  return (
    <Form onClose={onClose} title={title}>
      <div className="FormProducto workspace">
        <div className="mainForm">
          <FormNavigation factura={factura} section={section} setSection={setSection} action={facturaActionType} />
          {displaySection(section)}
        </div>
        <div className="sidebarForm">
          <Sidebar
            factura={factura}
            setFactura={setFactura}
            setSection={setSection}
            handleChange={handleChange}
            onClose={onClose}
            action={facturaActionType}
            setModal={setModal}
          />
        </div>
      </div>
    </Form>
  )
}

export default FormFacturasElectronicas
