import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as Updated } from '../../../assets/updated.svg'
import { auth } from '../../../firebase'
import './../TopBar.css'
import { setUser } from 'actions/users'
import useUser from 'hooks/useUser'

export const createAlertConfirm = (title, description, callback) => {
  const closeAlert = () => {
    const element = document.querySelector('.alertContainer')

    if (element) {
      element.className = 'alertContainer toggled'
    }
  }

  const alertContainer = document.querySelector('.alertContainer')
  const TitleAlert = document.querySelector('.TitleAlert')
  const descriptionAlert = document.querySelector('.descriptionAlert')
  const ConfirmAlert = document.querySelector('.ConfirmAlert')
  const Cancel = document.querySelector('.closeAlert')

  alertContainer.className = 'alertContainer'
  TitleAlert.innerHTML = title
  descriptionAlert.innerHTML = description

  const controller = () => {
    callback()
    closeAlert()
    ConfirmAlert.removeEventListener('click', controller)
  }

  ConfirmAlert.addEventListener('click', controller)
  Cancel.addEventListener('click', closeAlert)
}

const UserModal = ({ onToggle }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { savetaxInfo, isOnExchange } = useUser()

  const changeTransferUser = () => {
    console.log(auth.currentUser)
    dispatch({ type: 'RESET' })
    dispatch(setUser(auth.currentUser))
    navigate('/')
  }

  const handleLogout = async () => {
    createAlertConfirm(
      '¿Estás seguro de finalizar esta sesión?',
      'Podrás salir del aplicativo de manera segura, para volver a ingresar vuelva a autenticase',
      async () => {
        try {
          await auth.signOut()
          dispatch(setUser(null))
          dispatch({ type: 'RESET' })
          navigate('/ingresar')
          toast.info('Sesión cerrada', { toastId: 'toast-info' })
        } catch (error) {
          console.error(error)
        }
      }
    )
  }

  const setTitleSection = (title) => {
    let sectionTitle = document.querySelector('.titleSection')
    if (sectionTitle) {
      sectionTitle.innerHTML = title
    }
  }

  return (
    <div className="overloadUser hidden" role="button" onClick={onToggle}>
      <div className="userModal toggled">
        <div className="present">
          <div className="avatar">
            <i className="bi bi-person-circle iconPrimary" />
          </div>
          <div className="infoUser">
            <h4>{savetaxInfo?.nombreUsuario}</h4>
            <p>{savetaxInfo?.cia?.correoElectronico}</p>
          </div>
        </div>
        <ul>
          {isOnExchange && (
            <li>
              <button onClick={changeTransferUser} className="transferChange">
                Salir del Intercambio
                <Updated />
              </button>
            </li>
          )}

          <li>
            <Link to="/configuraciones/#informacionGeneral" onClick={(event) => setTitleSection('Configuraciones')} hidden="true">
              Informacion General
            </Link>
          </li>
          <li>
            <Link to="/configuraciones/#apariencia" onClick={(event) => setTitleSection('Configuraciones')} hidden="true">
              Apariencia
            </Link>
          </li>
          <li>
            <Link to="/configuraciones/#metodosDePago" onClick={(event) => setTitleSection('Configuraciones')} hidden="true">
              Metodos de Pago
            </Link>
          </li>
          <li>
            <Link to="/configuraciones/#historialDePagos" onClick={(event) => setTitleSection('Configuraciones')} hidden="true" >
              Historial de Pagos
            </Link>
          </li>
          <li>
            <Link to="/configuraciones/#apariencia" onClick={(event) => setTitleSection('Configuraciones')} hidden="true">
              Apariencia
            </Link>
          </li>
          <li>
            <button onClick={handleLogout} className="logOutUserBtn">
              Cerrar Sesión
              <i className="bi bi-box-arrow-left" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default UserModal
