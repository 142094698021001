import { createUsuario, updateUsuario } from 'actions/administracion'
import Form from 'components/Form/Form'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import ComprobantesElectronicos from './ComprobantesElectronicos'
import DetallesGenerales from './DetallesGenerales'
import './FormCompania.css'
import RegistroPymes from './RegistroPymes'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import { toast } from 'react-toastify'

const initForm = () => ({
  cia: {
    tipoDoctoPersona: {},
    tipoPersona: '2',
    celular: '',
    contraseniaFirmaElectronica: '',
    correoElectronico: '',
    direccion: '',
    fax: '',
    primeraVez: true,
    identificacionRepresentante: '',
    nombreCia: '',
    razonSocial: '',
    representanteLegal: '',
    resolucionIca: '',
    resolucionIva: '',
    ruc: '',
    telefonos: '',
    nombreLogo: '',
    ubicacionFirmaElectronica: '',
  },
})


const Navigation = ({ section, setSection }) => (
  <div className="navigationForm" style={{ width: '100%' }}>
    <button type="button" className={`btnSection ${section === 0 && 'active'}`} onClick={() => setSection(0)}>
      Detalles generales
    </button>
    <button type="button" className={`btnSection ${section === 1 && 'active'}`} onClick={() => setSection(1)}>
      Comprobantes Electrónicos
    </button>
    <button type="button" className={`btnSection ${section === 2 && 'active'}`} onClick={() => setSection(2)} hidden="true">
      Registro PYMES
    </button>
  </div>
)

const Sections = ({ section, company, setCompany }) => {
  const displaySection = useCallback(
    (s) => {
      switch (s) {
        case 0:
          return <DetallesGenerales company={company} setCompany={setCompany} />
        case 1:
          return <ComprobantesElectronicos company={company} setCompany={setCompany} />
        case 2:
          return <RegistroPymes company={company} setCompany={setCompany} />
        default:
          return ''
      }
    },
    [company, setCompany]
  )

  return <div className="sectionForm">{displaySection(section)}</div>
}

const FormCompania = ({ onClose, selectedCompany,actualizarUsuarios}) => {
  const dispatch = useDispatch()
  const { isEdit } = selectedCompany ?? {}
  const [section, setSection] = useState(0)
  const [company, setCompany] = useState(selectedCompany ?? initForm)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async(e) => {
    e.preventDefault()

    if (!company?.nombreCia) {
      toast.error('El nombre comercial es obligatorio')
      return
    }else if (!company?.razonSocial) {
      toast.error('La razón social es obligatoria')
      return
    }else if (!company?.ruc) {
      toast.error('El número de identifiación es obligatorio')
      return
    }else if (!company?.tipoDoctoPersona?.codigoSri) {
      toast.error('El tipo documento es obligatorio')
      return
    }else if (!company?.correoElectronico) {
      toast.error('El correo electrónico es obligatorio')
      return
    }else if (!company?.pais?.idPais) {
      toast.error('Selecciona el país')
      return
    }

    if(company?.pais?.idPais === 1){
      if (!company?.departamento?.idDepartamento) {
        toast.error('Selecciona la provincia')
        return
      }else if (!company?.idMunicipio?.idMunicipio) {
        toast.error('Selecciona el cantón')
        return
      }else if (!company?.distrito?.idDistrito ) {
        toast.error('Selecciona el distrito')
        return
      }else if (!company?.barrio?.idBarrio ) {
        toast.error('Selecciona el barrio')
        return
      }
    }

    if (!company?.direccion) {
      toast.error('La dirección es obligatoria')
      return
    }else  if (!company?.telefonos) {
      toast.error('El teléfono es obligatoria')
      return
    }

    setLoading(true)
    const action = isEdit ? updateUsuario : createUsuario

    await dispatch(action(company))
    onClose()
    setCompany({})
    actualizarUsuarios()
    setLoading(false)
  }

  return (
    <Form title="Mantenimiento de compañías" onClose={onClose} link={'https://www.youtube.com/watch?v=spmncKAHQhA'}>
      <form onSubmit={handleSubmit} className="workspace">
        <div className="mainForm">
          <Navigation section={section} setSection={setSection} />
          <Sections section={section} company={company} setCompany={setCompany} />
        </div>
        <div className="sidebarForm">
          <div className="actionForm">
          {loading ? <CircularProgress /> : <button className="saveForm" style={{ marginRight: 'auto', marginLeft: '0px' }} type="submit">
              {isEdit ? 'Actualizar' : 'Guardar'}
            </button>}
            
          </div>
        </div>
      </form>
    </Form>
  )
}

export default FormCompania
