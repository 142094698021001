/* eslint-disable no-unused-vars */
// Import Modules
import React, { useState, useEffect } from 'react'
import { db } from '../../../firebase'
import { v4 as uuidv4 } from 'uuid'

// Import Hooks
import useUser from 'hooks/useUser'

// Import Componnets
import FormatOption from './FormatOption'

// Import StyleSheet
import './Formats.css'

const Formats = () => {
  let [formatState, setFormatState] = useState(false)
  const { savetaxInfo } = useUser()

  useEffect(() => {
    if (savetaxInfo?.cia?.ruc) {
      let docRef = db.collection('configuraciones').doc(savetaxInfo.usuarioPK.codCia.toString())
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          return setFormatState(doc.data().formato)
        } else {
          docRef.set({
            formato: 1,
          })
        }
      })
    }
  }, [savetaxInfo])

  return (
    <div className="sectionMainConf" id="formatosDeFacturacion">
      <h4>Formatos de Factura</h4>
      <div className="confSection">
        <div className="formatsGroup">
          <FormatOption activeClass={formatState === 1 ? 'activeFormat' : false} formatName="FORMATO 01" format={1} />

          <FormatOption activeClass={formatState === 2 ? 'activeFormat' : false} formatName="FORMATO 02" format={2} />

          <FormatOption activeClass={formatState === 3 ? 'activeFormat' : false} formatName="FORMATO 03" format={3} />
        </div>
      </div>
    </div>
  )
}

export default Formats
