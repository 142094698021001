import Text from 'components/FormField/Text'
import React from 'react'

const RegistroPymes = () => {
  return (
    <div className="formComprobantes">
      <Text type="date" label="Fecha de inicio como Pymes" />
      <Text type="date" label="Fecha de inicio como PYMPA" />
    </div>
  )
}

export default RegistroPymes
