import { GET_USERS, GET_USER, UPDATE_USUARIO, DELETE_USUARIO, CREATE_USUARIO, LOAD_USER } from '../constants/actionTypes'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getInstance } from 'api/apiInstance'

export const getNotifications = async (cia) => {
  if (!cia) return null
  return await axios.get(`${process.env.REACT_APP_API_NOTIFICATION_URL}/notifications/getAllNotification/${cia}`)
}

export const getSavetaxInfo = async (user) => {
  let cedula = ''
  if (user?.email) {
    cedula = user.email.replace('@savetax.com', '')
  } else {
    cedula = user?.ruc
  }

  if (!cedula) return null
  const request = await getInstance()
  return await request.get(`users/find-by-identidad/${cedula}`)
}

export const setUser = (user) => {
  return async (dispatch) => {
    const savetaxInfo = await getSavetaxInfo(user ?? null)
    //const notifications = await getNotifications(savetaxInfo ? savetaxInfo.data.usuarioPK.codCia : null)
    dispatch({
      type: GET_USER,
      payload: {
        user,
        savetaxInfo: savetaxInfo?.data ?? null,
        //notifications: notifications?.data ?? null,
        notifications: null,
      },
    })
  }
}

export const getUsers = (cia) => async (dispatch) => {

  dispatch({
    type: LOAD_USER,
    payload: {},
  })

  const request = await getInstance()
  const response = await request.get(`users/find-by-cod-cia/${cia}`)
  
  dispatch({
    type: GET_USERS,
    payload: {
      users: response?.data,
    },
  })
}

export const createUsuario = (usuario) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.post('users',usuario)

    /*dispatch({
      type: CREATE_USUARIO,
      payload: {
        usuario: usuario,
      },
    })*/
    toast.success('Usuario creada correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema creando la usuario')

    return false
  }
}

export const updateUsuario = (usuario) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.post('users/edit',usuario)

    dispatch({
      type: UPDATE_USUARIO,
      payload: {
        usuario: usuario,
      },
    })
    toast.success('Usuario actualizado correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema actualizando el usuario')

    return false
  }
}

export const deleteUsuario = (usuario) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.delete(`users/${usuario.cia.codCia}/${usuario.usuarioPK.idUsuario}`)

    dispatch({
      type: DELETE_USUARIO,
      payload: {
        usuario: usuario,
      },
    })
    toast.success('Se eliminó correctamente la usuario')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema eliminando la usuario')

    return false
  }
}
