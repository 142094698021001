import React from 'react'
import { Link } from 'react-router-dom'
import { auth } from '../../firebase'
import './Recuperar.css'
import logo from '../../assets/logo_black.png'
import ilustratorRecuperar from '../../assets/ilustrator_recuperar.jpg'

const Recuperar = () => {
  const handleLogin = async () => {
    let email = document.querySelector('input.email').value.includes('@')
      ? document.querySelector('input.email').value
      : document.querySelector('input.email').value + '@savetax.com'
    let password = document.querySelector('input.password').value
    try {
      await auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      let errorCode = error.code
      let errorMessage = error.message
      console.erorr(errorCode, errorMessage)
    }
  }

  return (
    <div className="Ingresar">
      <div className="illustrationIngresar">
        <img src={ilustratorRecuperar} alt="" />
      </div>
      <div className="FormLoginContainer">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        {/* <h2 className='titleIngresar'>Bienvenido a SaveTax Facturador</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie dolor ligula, at tincidunt tortor eleifend eu.</p> */}
        <div className="Form">
          <h4>Recuperar Credenciales</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie dolor ligula, at tincidunt tortor eleifend eu.{' '}
          </p>
          <label htmlFor="">
            Correo Electrónico
            <div className="field">
              <input type="text" placeholder="Correo Electrónico" className="email" />
            </div>
          </label>
          <div className="optionsSecondaryForm">
            <Link to="/ingresar">Intentar Ingreso</Link>
          </div>
          <button className="btnIngresar" onClick={handleLogin}>
            Enviar Correo
          </button>
          <p className="registerLink">
            ¿No tienes una cuenta? <Link to="/registro">Registrate</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Recuperar
