// Users
export const GET_USER = 'GET_USER'
export const GET_USERS = 'GET_USERS'
export const CREATE_USUARIO = 'CREATE_USUARIO'
export const UPDATE_USUARIO = 'UPDATE_USUARIO'
export const DELETE_USUARIO = 'DELETE_USUARIO'
export const IS_ON_EXCHANGE = 'IS_ON_EXCHANGE'

// Administración
export const GET_ROLES = 'GET_ROLES'
export const GET_SUCURSALES = 'GET_SUCURSALES'
export const CREATE_SUCURSALES = 'CREATE_SUCURSALES'
export const UPDATE_SUCURSALES = 'UPDATE_SUCURSALES'
export const DELETE_SUCURSALES = 'DELETE_SUCURSALES'
export const GET_CAJAS = 'GET_CAJAS'
export const CREATE_CAJA = 'CREATE_CAJA'
export const UPDATE_CAJA = 'UPDATE_CAJA'
export const DELETE_CAJA = 'DELETE_CAJA'
export const GET_USUARIOS = 'GET_USUARIOS'
export const GET_CIA = 'GET_CIA'
export const LOAD_USUARIOS = 'LOAD_USUARIOS'
export const LOAD_CIA = 'LOAD_CIA'
export const LOAD_USER = 'LOAD_USER'
export const LOAD_CAJAS = 'LOAD_CAJAS'
export const CREATE_ADMIN_USUARIO = 'CREATE_ADMIN_USUARIO'
export const UPDATE_DATA_ADMIN_USUARIO = 'UPDATE_DATA_ADMIN_USUARIO'
export const UPDATE_ADMIN_USUARIO = 'UPDATE_ADMIN_USUARIO'
export const DELETE_ADMIN_USUARIO = 'DELETE_ADMIN_USUARIO'
export const GET_CONSECUTIVOS = 'GET_CONSECUTIVOS'
export const CREATE_CONSECUTIVO = 'CREATE_CONSECUTIVO'
export const UPDATE_CONSECUTIVO = 'UPDATE_CONSECUTIVO'
export const DELETE_CONSECUTIVO = 'DELETE_CONSECUTIVO'
export const GET_CABYS = 'GET_CABYS'
export const GET_ACTIVIDADES_ECONOMICAS = 'GET_ACTIVIDADES_ECONOMICAS'
export const CREATE_ACTIVIDAD_ECONOMICA = 'CREATE_ACTIVIDAD_ECONOMICA'
export const UPDATE_ACTIVIDAD_ECONOMICA = 'UPDATE_ACTIVIDAD_ECONOMICA'
export const DELETE_ACTIVIDAD_ECONOMICA = 'DELETE_ACTIVIDAD_ECONOMICA'

// Clientes
export const GET_CLIENTES = 'GET_CLIENTES'
export const UPLOAD_CLIENTE = 'UPLOAD_CLIENTE'
export const UPDATE_CLIENTE = 'UPDATE_CLIENTE'
export const GET_TIPOS_CLIENTES = 'GET_TIPOS_CLIENTES'

// Documentos
export const GET_DOCUMENTOS_RECIBIDOS = 'GET_DOCUMENTOS_RECIBIDOS'
export const GET_DOCUMENTS_EMITIDOS = 'GET_DOCUMENTOS_EMITIDOS'
export const UPDATE_DOCUMENT_RECIBIDO = 'UPDATE_DOCUMENT_RECIBIDO'

// Facturas
export const GET_FACTURAS_ELECTRONICAS = 'GET_FACTURAS_ELECTRONICAS'
export const GET_FACTURAS_ELECTRONICAS_POR_COMPRA = 'GET_FACTURAS_ELECTRONICAS_POR_COMPRA'
export const GET_FACTURAS_ELECTRONICAS_POR_EXPORTACION = 'GET_FACTURAS_ELECTRONICAS_POR_VENTA'
export const GET_TIQUETAS_ELECTRONICAS = 'GET_TIQUETAS_ELECTRONICAS'
export const GET_NOTAS_DE_CREDITO = 'GET_NOTAS_DE_CREDITO'
export const GET_NOTAS_DE_DEBITO = 'GET_NOTAS_DE_DEBITO'
export const GET_PROFORMAS_ELECTRONICAS = 'GET_PROFORMAS_ELECTRONICAS'
export const CREATE_FACTURA = 'CREATE_FACTURA'
export const UPDATE_FACTURA = 'UPDATE_FACTURA'

// * CREATE FACTURAS *
export const CREATE_FACTURA_ELECTRONICA = 'CREATE_FACTURA_ELECTRONICA'
export const CREATE_TIQUETA_ELECTRONICA = 'CREATE_TIQUETA_ELECTRONICA'
export const CREATE_FACTURA_EXPORTACION = 'CREATE_FACTURA_EXPORTACION'
export const CREATE_FACTURA_COMPRA = 'CREATE_FACTURA_COMPRA'
export const CREATE_FACTURA_PROFORMA = 'CREATE_FACTURA_PROFORMA'

// * DELETE FACTURAS *
export const DELETE_FACTURA_ELECTRONICA = 'DELETE_FACTURA_ELECTRONICA'

// Productos
export const GET_PRODUCTOS = 'GET_PRODUCTOS'
export const UPLOAD_PRODUCTO = 'UPLOAD_PRODUCTO'
export const UPDATE_PRODUCTO = 'UPDATE_PRODUCTO'

// Misc
export const GET_MONEDAS = 'GET_MONEDAS'
export const GET_METODOS_DE_PAGO = 'GET_METODOS_DE_PAGO'
export const GET_CONDICIONES_DE_VENTA = 'GET_CONDICIONES_DE_VENTA'
export const GET_UNIDADES_DE_MEDIDA = 'GET_UNIDADES_DE_MEDIDA'
export const GET_OTROS_CARGOS = 'GET_OTROS_CARGOS'
export const GET_TIPOS_IVA = 'GET_TIPOS_IVA'
export const GET_TIPOS_DOC_EXONERA = 'GET_TIPOS_DOC_EXONERA'
export const GET_TIPOS_IMPUESTOS = 'GET_TIPOS_IMPUESTOS'
export const GET_TIPOS_DOCS = 'GET_TIPOS_DOCS'
export const GET_TARIFAS = 'GET_TARIFAS'
export const GET_CATALOGO_ACTIVIDADES = 'GET_CATALOGO_ACTIVIDADES'
export const GET_DATOS_GEOGRAFICOS = 'GET_DATOS_GEOGRAFICOS'
export const GET_CODIGOS_REFERENCIA = 'GET_CODIGOS_REFERENCIA'
