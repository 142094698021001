// Import Modules
import React from 'react'
import { NavLink } from 'react-router-dom'

// Import StyleSheet
import './IndexesConf.css'

const IndexesConf = () => {
  return (
    <div className="indexConf">
      <ul>
        <li className="indexConfItem">
          <NavLink to="/configuraciones/#informacionGeneral">Información General</NavLink>
        </li>
        <li className="indexConfItem">
          <NavLink to="/configuraciones/#apariencia">Apariencia</NavLink>
        </li>
        <li className="indexConfItem">
          <NavLink to="/configuraciones/#formatosDeFactura">Formatos de Factura</NavLink>
        </li>
        <li className="indexConfItem">
          <NavLink to="/configuraciones/#historial">Historial y Metodos de pago</NavLink>
        </li>
      </ul>
    </div>
  )
}

export default IndexesConf
