/* eslint-disable no-unused-vars */
import rootReducer from 'store'
import {
  CREATE_USUARIO,
  DELETE_USUARIO,
  GET_USER,
  GET_USERS,
  IS_ON_EXCHANGE,
  UPDATE_USUARIO,
  LOAD_USER,
  USER_LOGOUT,
} from '../constants/actionTypes'

const initialState = {
  user: null,
  savetaxInfo: {},
  users: [],
  notifications: [],
  isLoadingUsers: true,
  isLoadingUser: true,
}

const handles = {
  [GET_USER]: (state, { payload = {} }) => {
    if (!payload?.user) {
      return {
        ...state,
        user: null,
        savetaxInfo: null,
        isLoadingUser: false,
      }
    }

    return {
      ...state,
      isLoadingUser: false,
      user: payload?.user,
      savetaxInfo: payload?.savetaxInfo,
      notifications: payload?.notifications,
    }
  },
  [LOAD_USER]: (state, { payload = {} }) => {
    return { ...state, isLoadingUsers: true }
  },
  [GET_USERS]: (state, { payload = {} }) => {
    return { ...state, isLoadingUsers: false, users: payload?.users }
  },
  [CREATE_USUARIO]: (state, { payload = {} }) => ({
    ...state,
    users: [payload?.usuario, ...state.users],
  }),
  [UPDATE_USUARIO]: (state, { payload = {} }) => ({
    ...state,
    users: state.users.map((u) =>
      u.usuarioPK?.codCia === payload?.usuario?.usuarioPK?.codCia &&
      u.usuarioPK?.idUsuario === payload?.usuario?.usuarioPK?.idUsuario
        ? payload?.usuario
        : u
    ),
  }),
  [DELETE_USUARIO]: (state, { payload = {} }) => ({
    ...state,
    users: state.users.filter(
      (u) =>
        !(
          u.usuarioPK?.codCia === payload?.usuario?.usuarioPK?.codCia &&
          u.usuarioPK?.idUsuario === payload?.usuario?.usuarioPK?.idUsuario
        )
    ),
  }),
  [IS_ON_EXCHANGE]: (state, { payload }) => ({
    ...state,
    isOnExchange: payload,
  }),
}

const user = (state = initialState, action) => {
  const { type } = action

  return handles[type] ? handles[type](state, action) : state
}

export default user
