/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// Import Modules
import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

// Import Hooks
import useUser from 'hooks/useUser'
import useDocumentos from 'hooks/useDocumentos'

// Import Components
import ShareDocumento from '../ShareDocumento/ShareDocumento'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import KeyviewDocumentosRecibidos from '../KeyviewDocumentosRecibidos'
import { getDocumentosRecibidos } from 'actions/documentos'
import { useDispatch } from 'react-redux'
import { deleteFactura } from 'actions/facturas'
import { getInstance,getURL } from 'api/apiInstance'
import { getDocumentosEmitidos } from 'actions/documentos'

// Import Handlers

// Import Context
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const LectorPDF = ({ sectionLector }) => {
  const { savetaxInfo } = useUser()
  let params = useParams()
  const dispatch = useDispatch()
  const [numPages, setNumPages] = useState(null)
  const { documentosRecibidos, documentosEmitidos } = useDocumentos()
  const [pagesNum, setPagesNum] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [zoom, setZoom] = useState(50)
  const [ajustZoom, setAjustZoom] = useState(false)
  const [documentData, setDocumentData] = useState()
  const [open, setOpen] = useState(false)
  const [documentosRecibidosFiltrados, setDoucumentosRecibidosFiltrados] = useState([])
  const [selectedId, setSelectedId] = useState(null)
  const [documentStatus, setDocumentStatus] = useState(null)
  const [openKeyview, setOpenKeyview] = useState(false)
  const [keyviewType, setKeyviewType] = useState(null)
  const [recargar, setRecargar] = useState(0)
  const [lectorState, setLectorState] = useState({
    fileUrl: null,
    base64PDF: null,
    base64XML: null,
    title: null,
    backUrl: '/',
    siguienteDocumentoEnlace: null,
    anteriorDocumentoEnlace: null,
  })

  const createPages = (numPages) => {
    let pages = []
    for (let i = 1; i < numPages + 1; i++) {
      pages.push(i)
    }
    setPagesNum(pages)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    let loadPDF = document.querySelector('.loadPDF')
    if (loadPDF) {
      setTimeout(() => {
        loadPDF.className = 'loadPDF hidden'
      }, 1000)
      setNumPages(numPages)
      createPages(numPages)
    }
  }

  const getDocumentoForID = async(idDocumento) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        if (documentosRecibidos) {
          return documentosRecibidos.find((doc) => doc.docRecibidosPK.id.toString() === idDocumento)
        }
      } else if (sectionLector === 'documentosEmitidos') {
        if (documentosEmitidos) {
          let doc = documentosEmitidos.find((doc) => doc.vtaMovmPK.numDocto.toString() === idDocumento)
          console.log("======>",doc,recargar)
          if(doc == undefined){
            await dispatch(getDocumentosEmitidos(savetaxInfo.cia.codCia))
            setRecargar(recargar + 1)
          }else{
            return doc
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getBase64PDF = async (documentoForID) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        const request = await getInstance("documentos")
        const base64 = await request.get(`doc-recibido/pdf-base64/${savetaxInfo.cia.codCia}/${documentoForID.docRecibidosPK.id}`)
        return base64.data
      } else if (sectionLector === 'documentosEmitidos') {
        const request = await getInstance("fe")
        const base64 = await request.get(`vta-movm/pdf-base64/${savetaxInfo.cia.codCia}/${documentoForID.tipoDoctoVta.idTipoDoctoVta}/1/${documentoForID.vtaMovmPK.numDocto}`)

        return base64.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getBase64Key = async (documentoForID) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        const request = await getInstance("documentos")
        const base64 = await request.get(`doc-recibido/xml-base64/${savetaxInfo.cia.codCia}/${documentoForID.docRecibidosPK.id}`)
        return base64.data
      } else if (sectionLector === 'documentosEmitidos') {

        const request = await getInstance("fe")
        const base64 = await request.get(`vta-movm/xml-base64/${savetaxInfo.cia.codCia}/${documentoForID.tipoDoctoVta.idTipoDoctoVta}/1/${documentoForID.vtaMovmPK.numDocto}`)

        return base64.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getFileUrl = (documentoForID) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        return `${getURL("documentos")}doc-recibido/pdf/${savetaxInfo.cia.codCia}/${documentoForID.docRecibidosPK.id}`
      } else if (sectionLector === 'documentosEmitidos') {
        return `${getURL("fe")}vta-movm/pdf/${savetaxInfo.cia.codCia}/${documentoForID.tipoDoctoVta.idTipoDoctoVta}/${documentoForID.sucursal.sucursalPK.idSucursal}/${documentoForID.vtaMovmPK.numDocto}`
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getTitleDoc = (documentoData) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        return `${documentoData.tipoDoctoVta.nombreDoctoVta} ${documentoData.emisor} #${params.idDocumento}`
      } else if (sectionLector === 'documentosEmitidos') {
        return `${documentoData.tipoDoctoVta.nombreDoctoVta} ${documentoData.cliente.nombreCliente} #${params.idDocumento}`
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getCurrentPosition = (idDocumento) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        if (documentosRecibidos) {
          return documentosRecibidos.findIndex((doc) => doc.docRecibidosPK.id.toString() === idDocumento)
        }
      } else if (sectionLector === 'documentosEmitidos') {
        if (documentosEmitidos) {
          return documentosEmitidos.findIndex((doc) => doc.vtaMovmPK.numDocto.toString() === idDocumento)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getSiguienteDocumentoEnlace = (positionCurrentDoc) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        if (!documentosRecibidos) return null
        let doc = documentosRecibidos[positionCurrentDoc + 1]
        if (!doc) return null
        return `/documentosRecibidos/${doc.docRecibidosPK.id}`
      } else if (sectionLector === 'documentosEmitidos') {
        if (!documentosEmitidos) return null
        let doc = documentosEmitidos[positionCurrentDoc + 1]
        if (!doc) return null
        return `/documentosEmitidos/${doc.vtaMovmPK.numDocto}`
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getAnteriorDocumentoEnlace = (positionCurrentDoc) => {
    try {
      if (sectionLector === 'documentosRecibidos') {
        if (!documentosRecibidos) return null
        let doc = documentosRecibidos[positionCurrentDoc - 1]
        if (!doc) return null
        return `/documentosRecibidos/${doc.docRecibidosPK.id}`
      } else if (sectionLector === 'documentosEmitidos') {
        if (!documentosEmitidos) return null
        let doc = documentosEmitidos[positionCurrentDoc - 1]
        if (!doc) return null
        return `/documentosEmitidos/${doc.vtaMovmPK.numDocto}`
      }
    } catch (error) {
      console.error(error)
    }
  }

  const showModalShare = () => {
    const ShareDocumentoModal = document.querySelector('.ShareDocumentoModal')
    ShareDocumentoModal.className = 'ShareDocumentoModal'
  }

  const incrementZoom = () => {
    setZoom((prevState) => {
      return prevState + 25
    })
    setAjustZoom(false)
  }

  const decrementZoom = () => {
    setZoom((prevState) => {
      return prevState - 25
    })
    setAjustZoom(false)
  }

  const ajustZoomChange = () => {
    setAjustZoom((prevState) => {
      if (prevState) {
        return false
      } else {
        return true
      }
    })
  }

  const hadleAnularDoc = async () => {
    let doc = getDocumentoForID(params.idDocumento)
    createAlertConfirm(
      `¿Estás seguro de anular ${doc.referencia}?`,
      `Podras anular el documento ${doc.referencia}, esta accion no es puede ser revertida`,
      async () => {
        try {
          dispatch(deleteFactura(doc))
          toast.success(`Documento ${doc.referencia} fue anulada`)
        } catch (error) {
          console.log(error)
        }
      }
    )
  }

  /* const hadleAceptarDoc = async () => {
    let doc = getDocumentoForID(params.idDocumento)
    createAlertConfirm(
      `¿Estás seguro de aceptar el documento #${doc.docRecibidosPK.id}?`,
      `Podras aceptar el documento ${doc.docRecibidosPK.id}, esta accion no es puede ser revertida`,
      async () => {
        try {
          toast.success(`Documento #${doc.docRecibidosPK.id} fue aceptado`)
        } catch (error) {
          console.log(error)
        }
      }
    )
  }

  const hadleRechazarDoc = async () => {
    let doc = getDocumentoForID(params.idDocumento)
    createAlertConfirm(
      `¿Estás seguro de rechazar el documento #${doc.docRecibidosPK.id}?`,
      `Podras rechazar el documento ${doc.docRecibidosPK.id}, esta accion no es puede ser revertida`,
      async () => {
        try {
          toast.success(`Documento #${doc.docRecibidosPK.id} fue rechazado`)
        } catch (error) {
          console.log(error)
        }
      }
    )
  } */

  const aceptarDocumento = (index) => {
    setOpenKeyview(true)
    setKeyviewType('accept')
    setSelectedId(index)
  }

  const rechazarDocumento = (index) => {
    setOpenKeyview(true)
    setKeyviewType('reject')
    setSelectedId(index)
  }

  const onCloseKeyview = () => {
    setOpenKeyview(false)
    setKeyviewType(null)

    if (!open) {
      setSelectedId(null)
    }
  }

  useEffect(() => {
    if (!documentosRecibidos.length) {
      dispatch(getDocumentosRecibidos(savetaxInfo.cia.codCia))
    }

    setDoucumentosRecibidosFiltrados(documentosRecibidos)
  }, [dispatch, documentosRecibidos, savetaxInfo])

  useEffect(() => {
    let cargarDocumento = async () => {
      let documentoData = await getDocumentoForID(params.idDocumento)
      let positionCurrentDoc = await getCurrentPosition(params.idDocumento)

      console.log("=== documentoData",documentoData)

      if (documentoData) {
        let fileUrl = getFileUrl(documentoData)
        let base64PDF = await getBase64PDF(documentoData)
        let base64Key = await getBase64Key(documentoData)
        let tituloDocumento = getTitleDoc(documentoData)
        let anteriorDocumentoEnlace = getAnteriorDocumentoEnlace(positionCurrentDoc)
        let siguienteDocumentoEnlace = getSiguienteDocumentoEnlace(positionCurrentDoc)
        setLectorState({
          fileUrl: fileUrl,
          base64PDF: base64PDF,
          base64XML: base64Key,
          title: tituloDocumento,
          backUrl: window.location.pathname.replace(params.idDocumento, ''),
          anteriorDocumentoEnlace,
          siguienteDocumentoEnlace,
        })
        setDocumentStatus(documentoData.descripcionSri)
      }
    }

    const document = getCurrentPosition(params.idDocumento)
    setDocumentData(document)
    cargarDocumento()
  }, [documentosEmitidos, documentosRecibidos, params, documentData, document,recargar])

  return (
    <div className={`readerPDF z-${ajustZoom ? 'ajust' : zoom}`}>
      <div className="navReaderPDF">
        <Link to={lectorState.backUrl} className="closeReader">
          <i className="bi bi-arrow-left"></i>
        </Link>
        <h3 className="titleReader">{lectorState.title}</h3>
        <div className="zoomContainer">
          <span className="numberPages">
            <b>{pageNumber}</b> / {numPages}
          </span>
          <div className="zoomController">
            <button disabled={zoom === 50 ? true : false} onClick={decrementZoom}>
              <i className="bi bi-dash-lg"></i>
            </button>
            <span>{zoom}%</span>
            <button disabled={zoom === 200 ? true : false} onClick={incrementZoom}>
              <i className="bi bi-plus-lg"></i>
            </button>
          </div>
          <button className="ajustDocument" onClick={ajustZoomChange}>
            <i className="bi bi-arrows-expand"></i>
          </button>
        </div>
        <div className="setActions">
          <button onClick={showModalShare} className="downloadReader" title="Compartir Documento">
            <i className="bi bi-share"></i>
          </button>
          <a
            download={'PDF ' + lectorState.title + '.pdf'}
            href={'data:application/octet-stream;base64,' + lectorState.base64PDF}
            className="downloadReader"
            title="Descargar PDF"
          >
            <i className="bi bi-filetype-pdf"></i>
          </a>
          <a
            download={'XML ' + lectorState.title + '.xml'}
            href={'data:application/octet-stream;base64,' + lectorState.base64XML}
            className="keyReader"
            title="Descargar XML"
          >
            <i className="bi bi-filetype-xml"></i>
          </a>
          {sectionLector === 'documentosRecibidos' && (
            <>
              {!documentStatus && (
                <button className="checkedReader" title="Aceptar Documento" onClick={() => aceptarDocumento(documentData)}>
                  <i className="bi bi-check-circle"></i>
                </button>
              )}
              {!documentStatus && (
                <button className="cancelDoc" title="Rechazar Documento" onClick={() => rechazarDocumento(documentData)}>
                  <i className="bi bi-x-circle"></i>
                </button>
              )}
            </>
          )}
          {sectionLector === 'documentosEmitidos' && (
            <button onClick={hadleAnularDoc} className="cancelDoc" title="Anular Documento">
              <i className="bi bi-trash"></i>
            </button>
          )}
          {sectionLector === 'facturaElectronica' && (
            <button className="resendDoc" title="Enviar a Correo">
              <i className="bi bi-send"></i>
            </button>
          )}
        </div>
      </div>
      <div className="viewContainer">
        <div className="pagesSidebar">
          {pagesNum.map((page, index) => (
            <React.Fragment key={`page-number-${index}`}>
              <div onClick={() => setPageNumber(index + 1)} style={{ textAlign: 'center' }}>
                <Document file={lectorState.fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={page} />
                </Document>
                <p style={{ textAlign: 'center' }}>Pagina {page}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="loadPDF">
          <div className="snippet"></div>
          <span className="porcent">Cargando...</span>
        </div>
        <Document file={lectorState.fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        {lectorState.anteriorDocumentoEnlace !== null && (
          <Link to={lectorState.siguienteDocumentoEnlace} className="pagination before">
            <i className="bi bi-chevron-left"></i>
          </Link>
        )}
        {lectorState.siguienteDocumentoEnlace !== null && (
          <Link to={lectorState.anteriorDocumentoEnlace} className="pagination next">
            <i className="bi bi-chevron-right"></i>
          </Link>
        )}
      </div>
      <ShareDocumento title={lectorState.title} />
      {documentData >= 0 && (
        <KeyviewDocumentosRecibidos
          documents={documentosRecibidosFiltrados}
          selectedId={selectedId}
          open={openKeyview}
          type={keyviewType}
          onClose={onCloseKeyview}
        />
      )}
    </div>
  )
}

export default LectorPDF
