import { GET_CLIENTES, GET_TIPOS_CLIENTES, UPLOAD_CLIENTE, UPDATE_CLIENTE } from '../constants/actionTypes'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getInstance } from 'api/apiInstance'

export const getClients = (cia) => {
  return async (dispatch) => {
    const request = await getInstance("personas")
    const response = await request.get(`cliente/find-by-cod-cia/${cia}`)

    dispatch({
      type: GET_CLIENTES,
      payload: {
        clientes: response?.data,
      },
    })
  }
}

export const uploadClient = (data, onClose) => async (dispatch) => {
  const request = await getInstance("personas")
  const response = await request.post(`cliente`,data)

  onClose()
  toast.success('Cliente agregado')

  dispatch({
    type: UPLOAD_CLIENTE,
    payload: {
      cliente: response.data,
    },
  })
}

export const updateClient = (data, onClose) => async (dispatch) => {
  try {
    const request = await getInstance("personas")
    const response = await request.put(`cliente`,data)
    
    dispatch({
      type: UPDATE_CLIENTE,
      payload: {
        cliente: data,
      },
    })
    console.log(dispatch)
    onClose()
    toast.success('Cliente actualizado')
  } catch (err) {
    console.log(err)
    toast.error('Error al actualizar')
  }
}

export const getTiposClientes = async (dispatch) => {
  const request = await getInstance()
  const response = await request.get(`tipocliente`)
  dispatch({
    type: GET_TIPOS_CLIENTES,
    payload: {
      tiposClientes: response?.data,
    },
  })
}
