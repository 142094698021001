// Import Modules
import React from 'react'
import moment from 'moment'
import axios from 'axios'
import { Link } from 'react-router-dom'

// Import Hook
import useUser from 'hooks/useUser'

// Import Envirovement Variable
const { REACT_APP_API_NOTIFICATION_URL } = process.env

const NotificationModal = ({ onToggle }) => {
  const { savetaxInfo, notifications } = useUser()
  const checkNotification = (index, event) => {
    event.preventDefault()
    let newNotifications = notifications.filter((notification, i) => i !== index)
    axios.delete(`${REACT_APP_API_NOTIFICATION_URL}/notifications/deleteNitifications/${savetaxInfo.usuarioPK.codCia}`, {
      data: newNotifications,
    })
  }

  return (
    <div className="overloadNofications hidden" role="button" onClick={onToggle}>
      <div className="modalNofications toggled">
        {!notifications?.length > 0 && (
          <div className="emptyNotifications">
            <i className="bi bi-bell-slash"></i>
            <h4>No hay Notificaciones</h4>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores, dolorem.</p>
          </div>
        )}
        {notifications?.length > 0 && (
          <div className="sectionNotification">
            <h4>
              Notificaciones
              <Link className="allNotifications" to="/notificaciones">
                Ver todas las notificaciones
              </Link>
            </h4>
            <div className="itemsSectionNotification">
              {notifications.map((item, index) => (
                <div key={`item-number-${index}`} className="item">
                  <Link to={item.payload.urlTarget}>
                    <div className="info">
                      <b>{item.payload.title}</b>
                      <p>{item.payload.body}</p>
                    </div>
                    <span className="date">{moment(item.date).format('L')}</span>
                    <button className="checkNotification" onClick={(event) => checkNotification(index, event)}>
                      <i className="bi bi-check-lg"></i>
                    </button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationModal
