/* eslint-disable react-hooks/exhaustive-deps */
// Import Modules
import React, { useState, useEffect } from 'react'
import Toggle from 'react-toggle'
import { db } from '../../../firebase'
import { toast } from 'react-toastify'

// Import Hooks
import useUser from 'hooks/useUser'

const ToogleShowNotifications = () => {
  const { savetaxInfo } = useUser()

  const [showNotification, setShowNotification] = useState(false)

  const getShownotificationSetting = async () => {
    try {
      if (!savetaxInfo?.cia?.ruc) toast.error('No fue posible obtener la configuracion de mostrar la notificaciones')
      let refDoc = db.collection('notifications').doc(savetaxInfo.usuarioPK.codCia.toString())
      let doc = await refDoc.get()
      let docData = doc.data()
      setShowNotification(docData.showNotifications)
    } catch (error) {
      console.error(error)
    }
  }

  const handlerToogleShowNotifications = async ({ target }) => {
    try {
      if (!savetaxInfo?.cia?.ruc) toast.error('No fue posible cambiar la configuracion de mostrar la notificaciones')
      let checked = target.checked
      let refDoc = db.collection('notifications').doc(savetaxInfo.usuarioPK.codCia.toString())
      await refDoc.update({ showNotifications: checked })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getShownotificationSetting()
  }, [savetaxInfo])

  useEffect(() => {
    if (savetaxInfo?.cia?.ruc) {
      db.collection('notifications')
        .doc(savetaxInfo.usuarioPK.codCia.toString())
        .onSnapshot((doc) => {
          let docData = doc.data()
          setShowNotification(docData?.showNotifications)
        })
    }
  }, [savetaxInfo])

  return (
    <div className="labelConf">
      <span>Mostrar notificaciones</span>
      <Toggle checked={showNotification} onChange={handlerToogleShowNotifications} />
    </div>
  )
}

export default ToogleShowNotifications
