/* eslint-disable react-hooks/exhaustive-deps */
// Import Hooks
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useUser from '../../hooks/useUser'

const PublicRoute = ({ element }) => {
  const { user, isLoadingUser } = useUser()
  let navigate = useNavigate()
  useEffect(() => {
    if (!isLoadingUser && user) {
      navigate('/escritorio')
    }
  }, [user, isLoadingUser])

  return element
}

export default PublicRoute
