/* eslint-disable no-unused-vars */
// Import Modules
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Outlet } from 'react-router-dom'

// Import Handler
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'

// Import Components
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Toggle from 'react-toggle'
import { Link } from 'react-router-dom'

// Import StyleSheet
import './Methods.css'

// Import Hooks
import useUser from 'hooks/useUser'

const MethodsPages = () => {
  const { savetaxInfo } = useUser()
  const [filtros, setFiltros] = useState({
    search: '',
    fechaVencimiento: '',
  })
  const [methods, setMethods] = useState([])
  const [isLoadingMethods, setIsLoadingMethods] = useState(true)

  const deleteMethod = (id) => {
    createAlertConfirm(
      `¿Estás seguro de eliminar el metodo de pago Nro ${id}?`,
      `Podras eliminar el metodo de pago Nro ${id}, esta accion no es puede ser revertida`,
      () => {
        handlerDeleteMethod(id)
      }
    )
  }

  const handlerDeleteMethod = async (id) => {
    await axios.delete('https://savetax.facturacionsavetaxcr.com/savetax/api/v1/metodo-pago', {
      codCia: savetaxInfo.usuarioPK.codCia,
      id: id,
    })
  }

  const handleSetMethods = async (cia) => {
    let response = await axios.get(`https://savetax.facturacionsavetaxcr.com/savetax/api/v1/metodo-pago/find-by-cod-cia/${cia}`)
    if (!response?.data?.length) {
      setIsLoadingMethods(false)
      return
    }
    setMethods(response.data)
    setIsLoadingMethods(false)
  }

  useEffect(() => {
    if (savetaxInfo?.usuarioPK?.codCia) {
      handleSetMethods(savetaxInfo.usuarioPK.codCia)
    }
  }, [savetaxInfo])

  return (
    <div className="MethodsPages">
      <div className="navSection">
        <div className="dropDownFilter toggled" style={{ width: '75%' }}>
          <label style={{ width: '35%' }}>
            Buscar Metodo de Pago
            <div className="search">
              <input
                // value={filtros.search.toUpperCase()}
                type="text"
                placeholder="Buscar"
                // onChange={hadleFilter}
                name="search"
              />
              <button>
                <i className="bi bi-search"></i>
              </button>
            </div>
          </label>
          <label style={{ width: '35%' }}>
            Rango de fecha de emision
            <DateRangePicker value={null} />
          </label>
          <label
            style={{ width: '15%' }}
            // onClick={clearFilters}
          >
            Limpiar Filtros
            <button className="clearFilterButton">Limpiar</button>
          </label>
        </div>
        <Link to="/metodosPago/agregar" className="addContent" style={{ width: '20%' }}>
          Agregar Metodo de Pago
          <i className="bi bi-plus-circle-fill"></i>
        </Link>
      </div>
      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-1">
              <b>ID</b>
            </li>
            <li className="col-2">
              <b>Nombre</b>
            </li>
            <li className="col-2">
              <b>Ultimos Digitos</b>
            </li>
            <li className="col-2">
              <b>Vencimiento</b>
            </li>
            <li className="col-2">
              <b>Predeterminado</b>
            </li>
            <li className="col-1-2">
              <b></b>
            </li>
          </ul>
        </div>

        {isLoadingMethods ? (
          <div className="loadContentTable">
            <div className="snippet"></div>
            <span>Cargando Facturas Electronicas</span>
          </div>
        ) : (
          <div className="bodyList">
            <ul>
              {methods.length > 0 &&
                methods.slice(0, 50).map((method, index) => (
                  <li className="itemBodyList">
                    <ul className="contentItemBodyList">
                      <li className="col-1">
                        <span>{method.id}</span>
                      </li>
                      <li className="col-2">
                        <b>{method.nombre}</b>
                      </li>
                      <li className="col-2">
                        <span>{method.ultimosDigitos}</span>
                      </li>
                      <li className="col-2">
                        <span>{method.vencimiento}</span>
                      </li>
                      <li className="col-2">
                        <Toggle checked={method.predeterminada} />
                      </li>
                      <li className="col-1-2">
                        <Link className="btnEditItem" to={`/metodosPago/editar/${method.id}`}>
                          <i className="bi bi-eye"></i>
                        </Link>
                        <button className="btnDelete" onClick={() => deleteMethod(method.id)}>
                          <i className="bi bi-trash"></i>
                        </button>
                      </li>
                    </ul>
                  </li>
                ))}

              {!methods.length && (
                <li className="emptyResultTable">
                  <span>No hay Resultados</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <Outlet />
    </div>
  )
}

export default MethodsPages
